import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

import { ContentLanguage, InterfaceLanguage } from '../constants/languages';
import { GeneralFormErrors, NonFieldError } from '../Models/authentication';
import { Additive, Allergen, Label } from '../Models/declarations';
import { Diet, DietParams } from '../Models/diet';
import {
  CondensedDish,
  Dish,
  DishParams,
  SeparatorParams,
  SimpleDishAdditives,
  SimpleDishAllergens,
} from '../Models/dish';
import { AtLeast, GenericAction, Results } from '../Models/generics';
import { Ingredient, IngredientsParams } from '../Models/ingredients';
import {
  LocationGroup,
  LocationGroupsParams,
  LocationsParams,
  SimpleLocation,
} from '../Models/location';
import {
  TaskType,
  GenerateArchiveOptions,
  SimpleMenu,
  MenuPreviewData,
} from '../Models/menu';
import { MTFont } from '../Models/mtfont';
import { OnboardingTemplate } from '../Models/onboarding_template';
import { Partner } from '../Models/partners';
import { Recipe, RecipeParams } from '../Models/recipe';
import { Ruleset, RulesParams } from '../Models/ruleset';
import { CondensedSeparator } from '../Models/separator';
import { Layout, LayoutParams, TemplateParams } from '../Models/template';
import { Type, TypeParams } from '../Models/type';
import { Task, UserModule } from '../Models/user';
import { SimpleCollection, SimpleIntegration } from '../Models/integration';

export const setCurrentLocation = createAction(
  `[Set Location] Set current Location`,
  props<{ currentLocation: number }>(),
);

export const setInterfaceLang = createAction(
  `[Language API] Set current interface language`,
  props<{ lang: InterfaceLanguage }>(),
);

export const setGlobalMetaTags = createAction(
  `[Meta tags] Set global meta tags`,
  props<{ lang: InterfaceLanguage }>(),
);

export const showSnackbarMessage = createAction(
  `[Global] Show snackbar message, it can be an error or a normal message`,
  props<{
    errorCode?: number;
    message?: string;
    snackClass?: string;
    button?: string;
  }>(),
);

export const handleHttpError = createAction(
  `[API] Handle HTTP error returned from the server`,
  props<{
    error: HttpErrorResponse;
    formId?: string;
    forceSnackbar?: boolean;
  }>(),
);

export const setFormErrors = createAction(
  `[API] Add form error to the list of non_field_errors`,
  props<{ error: NonFieldError }>(),
);

export const setFieldErrors = createAction(
  `[API] Handle field errors`,
  props<{ payload: GeneralFormErrors }>(),
);

export const setSidePanelOpen = createAction(
  `[Global] Set side panel open`,
  props<{ open: boolean }>(),
);

export const getAllLocations = createAction(
  `[Locations API] Fetch non-paginated list of locations`,
  props<{ params: AtLeast<LocationsParams, 'pagination'> }>(),
);

export const setAllLocations = createAction(
  `[Locations API] Set list of locations locally`,
  props<{ payload: SimpleLocation[] }>(),
);

export const getAllLocationGroups = createAction(
  `[Locations API] Fetch non-paginated list of location groups`,
  props<{ params: AtLeast<LocationGroupsParams, 'pagination'> }>(),
);

export const setAllLocationGroups = createAction(
  `[Locations API] Set list of location groups locally`,
  props<{ payload: LocationGroup[] }>(),
);

export const setTemplatesState = createAction(
  '[Authentication] Set template state',
  props<{ loading: boolean }>(),
);

export const fetchTemplates = createAction(
  '[Authentication] Fetch onboarding templates',
);

export const fetchTemplatesMenu = createAction(
  '[Templates API] Fetch onboarding templates for a menu',
  props<{ current_menu: number }>(),
);

export const setTemplates = createAction(
  '[Authentication] Set Onboarding templates locally',
  props<{ data: OnboardingTemplate[] }>(),
);

export const setTemplatesLocation = createAction(
  '[Authentication] Set Onboarding templates for a location locally',
  props<{ data: OnboardingTemplate[] }>(),
);

export const addTemplate = createAction(
  '[Authentication] Add Onboarding template',
  props<{ template: OnboardingTemplate }>(),
);

export const removeTemplate = createAction(
  '[Authentication] Remove Onboarding template',
  props<{ id: number }>(),
);

export const setCondensedLayouts = createAction(
  `[Templates container] Set condensed templates locally`,
  props<{ payload: Layout[] }>(),
);

export const fetchOrderTakingLayouts = createAction(
  `[Templates API] Fetch order taking templates`,
  props<{ params: Partial<LayoutParams> }>(),
);

export const setOrderTakingLayouts = createAction(
  `[Templates API] Set order taking templates locally`,
  props<{ payload: Layout[] }>(),
);

export const fetchUsedTemplates = createAction(
  `[Templates API] Fetch used templates`,
  props<{ params: Partial<TemplateParams> }>(),
);

export const setUsedTemplates = createAction(
  `[Templates API] Set used templates locally`,
  props<{ payload: OnboardingTemplate[] }>(),
);

export const fetchPartnerByCode = createAction(
  `[Global] Fetches a partner object by code`,
  props<{ code: string }>(),
);

export const setPartner = createAction(
  `[Global] Set partner locally`,
  props<{ partner: Partner }>(),
);

export const uploadImage = createAction(
  `[API] Upload image to URL`,
  props<{
    actions?: [GenericAction, string][];
    callback?: (res: any) => void;
    image: FormData;
    url: string;
    params?: { current_menu?: number; current_recipe?: number };
  }>(),
);

export const fetchRules = createAction(
  `[Rules API] Fetch rules`,
  props<{ params: Partial<RulesParams> }>(),
);

export const setRules = createAction(
  `[Rules API] Set Rules locally`,
  props<{ payload: Results<Ruleset> }>(),
);

export const sendSupportForm = createAction(
  `[Global] Send support form`,
  props<{
    callback?: () => void;
    email: string;
    files?: File[];
    message: string;
    phone?: string;
    subject?: string;
  }>(),
);

export const setRecipesSidenavTopOffset = createAction(
  `[Recipes] Set top offset for recipes sidenav`,
  props<{ offset: number }>(),
);

export const setGlobalSpinner = createAction(
  `[Global] Set global spinner`,
  props<{ value: boolean }>(),
);

export const fetchFonts = createAction(`[Global] Fetch fonts`);

export const setFontsAsFilter = createAction(
  `[Global] Set fonts`,
  props<{ fonts: MTFont[] }>(),
);

export const fetchDishesAutocomplete = createAction(
  `[Global] Fetch autocomplete dishes`,
  props<{ params: Partial<DishParams> }>(),
);

export const fetchIngredientsAutocomplete = createAction(
  `[Global] Fetch autocomplete ingredients`,
  props<{ params: Partial<IngredientsParams> }>(),
);

export const createIngredientAutocomplete = createAction(
  `[Global] Create autocomplete ingredient`,
  props<{
    ingredient: Partial<Ingredient>;
    params: Partial<IngredientsParams>;
    onFulfilled?: (ing: Ingredient) => void;
  }>(),
);

export const updateIngredientAutocomplete = createAction(
  `[Global] Update autocomplete ingredient`,
  props<{
    ingredient: Partial<Ingredient>;
    url: string;
    params: Partial<IngredientsParams>;
    onFulfilled?: (ing: Ingredient) => void;
  }>(),
);

export const setDishesAutocomplete = createAction(
  `[Global] Set autocomplete dishes`,
  props<{ payload: Results<CondensedDish> }>(),
);

export const fetchSeparatorAutocomplete = createAction(
  `[Global] Fetch autocomplete separators`,
  props<{ params: Partial<SeparatorParams> }>(),
);

export const setAutocompleteSeparator = createAction(
  `[Global] Set autocomplete separator locally`,
  props<{ payload: CondensedSeparator[] }>(),
);

export const setIngredientsAutocomplete = createAction(
  `[Global] Set autocomplete ingredients`,
  props<{ payload: Results<Ingredient> }>(),
);

export const createDishAutocomplete = createAction(
  `[Global] Create dish`,
  props<{
    dish: Partial<Dish>;
    clear?: boolean;
    callback?: (dish: Dish) => void;
    errorCb?: () => void;
  }>(),
);

export const setUserModules = createAction(
  `[Global] Set user modules`,
  props<{ userModules: UserModule[] }>(),
);

export const setUserModulesLoaded = createAction(
  `[Global] Set initial user modules loaded locally`,
);

export const userModuleDisabled = createAction(
  '[Global] User module disabled',
  props<{ module: UserModule }>(),
);

export const userModuleEnabled = createAction(
  '[Global] User module enabled',
  props<{ module: UserModule }>(),
);

export const userModuleUpdated = createAction(
  '[Global] User module was updated',
  props<{ module: UserModule }>(),
);

export const fetchAllergens = createAction(
  `[Global] Fetch allergens`,
  props<{ params?: { current_location: number } }>(),
);

export const setAllergens = createAction(
  `[Global] Set allergens locally`,
  props<{ result: Allergen[] }>(),
);

export const fetchAdditives = createAction(
  `[Global] Fetch additives`,
  props<{ params?: { current_location: number } }>(),
);

export const setAdditives = createAction(
  `[Global] Set additives locally`,
  props<{ result: Additive[] }>(),
);

export const fetchLabels = createAction(
  `[Global] Fetch labels`,
  props<{ params?: { current_location: number } }>(),
);

export const setLabels = createAction(
  `[Global] Set labels locally`,
  props<{ result: Label[] }>(),
);

export const fetchTasks = createAction(`[Global] Fetch tasks`);

export const setTasks = createAction(
  `[Global] Set tasks locally`,
  props<{ tasks: Task[] }>(),
);

export const changeArchive = createAction(
  `[Global] Change archive`,
  props<{ archive: Partial<Task> }>(),
);

export const createArchive = createAction(
  `[Global] Create archive`,
  props<{
    menu: SimpleMenu;
    task_type: TaskType;
    options?: GenerateArchiveOptions;
  }>(),
);

export const fetchDeclarations = createAction(
  `[Global] Fetch all declarations (allergens, additives, labels)`,
  props<{ params?: { current_location: number } }>(),
);

export const fetchUserModules = createAction(`[Global] Fetch user modules`);

export const showPreviewDialog = createAction(
  `[Global] Show PDF preview component`,
  props<MenuPreviewData>(),
);

export const fetchPreviewHtml = createAction(
  `[Global] Fetch HTML preview`,
  props<{
    url: string;
    params?: any;
  }>(),
);

export const setPreviewHtml = createAction(
  `[Global] Set HTML preview locally`,
  props<{
    html: string;
  }>(),
);

export const clearPreviewHtml = createAction(
  `[Global] Clear HTML preview locally`,
);

export const fetchPreviewPdfHtml = createAction(
  `[Global] Fetch PDF HTML preview`,
  props<{
    url: string;
    params?: any;
  }>(),
);

export const setPreviewPdfHtml = createAction(
  `[Global] Set PDF HTML preview locally`,
  props<{
    html: string;
  }>(),
);

export const clearPreviewPdfHtml = createAction(
  `[Global] Clear PDF HTML preview locally`,
);

export const fetchDiets = createAction(
  '[Diets API] Fetch diets',
  props<{ params: Partial<DietParams> }>(),
);

export const fetchTypes = createAction(
  '[Types API] Fetch types',
  props<{ params: Partial<TypeParams> }>(),
);

export const fetchRecipesAutocomplete = createAction(
  '[Recipes API] Fetch recipes',
  props<{ params: Partial<RecipeParams> }>(),
);

export const setDiets = createAction(
  '[Diets API] Set diets locally',
  props<{ diets: Diet[] }>(),
);

export const setTypes = createAction(
  '[Types API] Set types locally',
  props<{ data: Type[] }>(),
);

export const setRecipes = createAction(
  '[Recipes API] Set recipes locally',
  props<{ recipes: Recipe[] }>(),
);

export const fetchSimilarDishes = createAction(
  `[Global] Fetch similar dishes`,
  props<{
    name: string;
    lang: ContentLanguage;
    containsOption: string;
    dish: Dish;
  }>(),
);

export const setSimilarDishesAllergens = createAction(
  `[Global] Set similar dishes with allergens`,
  props<{ result: Results<SimpleDishAllergens> }>(),
);

export const setSimilarDishesAdditives = createAction(
  `[Global] Set similar dishes with additives`,
  props<{ result: Results<SimpleDishAdditives> }>(),
);

export const addMoreDishes = createAction(
  `[Global] Add more dishes`,
  props<{ dishes: SimpleDishAllergens[]; nextUrl: string }>(),
);

export const addMoreDishesAdditives = createAction(
  `[Global] Add more dish additives`,
  props<{ dishes: SimpleDishAdditives[]; nextUrl: string }>(),
);

export const clearSimilarDishes = createAction(
  `[Global] Clear similar dishes data`,
);

export const fetchNextDishes = createAction(
  `[Global] Fetch next dishes`,
  props<{ dishes: Dish[] }>(),
);

export const fetchMoreDishes = createAction(
  `[Global] Fetch more dishes`,
  props<{ typeOfDish: string }>(),
);

export const clearRecipesAutocomplete = createAction(
  `[Global] Clear recipes autocomplete`,
);

export const setInitialMenuDataLoaded = createAction(
  `[Global] Sets initial menu data loaded locally`,
  props<{ value: boolean }>(),
);

export const clearData = createAction(`[Global] Clear shared data locally`);

export const fetchCollections = createAction(`[Global] Fetch collections`);

export const setCollections = createAction(
  `[Global] Set collections locally`,
  props<{ collections: SimpleCollection[] }>(),
);

export const fetchLinks = createAction(`[Global] Fetch links`);

export const setLinks = createAction(
  `[Global] Set links locally`,
  props<{ links: SimpleIntegration[] }>(),
);
